import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image";

const CollectionsPage = ({ data }) => {
  let title = data.shopifyCollection.title;
  let products = data.shopifyCollection.products;
  return (
    <section id={`${title}-collection`}>
      <h1>{title}</h1>
      <ul>
        {products.map(product => {
          let mainImage = product.images[0]
          return(
            <li>
              <Link to={`/shop/product/${product.handle}`}>
                {product.title}
                <Img
                  className='w-100'
                  fluid={mainImage.localFile.childImageSharp.fluid}
                  key={mainImage.id}
                  alt={product.title} />
              </Link>
            </li>
          )
        })}
      </ul>
    </section>
  )
};


export const query = graphql`
 query($handle: String!)  {
  shopifyCollection(handle: {eq: $handle}) {
    title
    products {
      title
      handle
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
}
`

export default CollectionsPage;
